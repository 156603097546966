import { Link, graphql } from "gatsby";
import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default ({ data }) => {
  const [bilaketa, setBilaketa] = useState('');
  const [emaitzak, setEmaitzak] = useState(null);
  const [bilatzen, setBilatzen] = useState(false);
  const posts = data.allWpPost .nodes;

  useEffect(() => {
    setBilatzen(!!bilaketa);
    const normalizedBilaketa = bilaketa.toLowerCase().trim();
    setEmaitzak(posts.filter(post => post.title.toLowerCase().includes(normalizedBilaketa)));
  }, [bilaketa]);

  return (
    <Layout  title={'Hiztegia'}>
      <Seo title="Hiztegia" />
      <h1 className="text-4xl">
        Azken artikuluak
      </h1>
        <div className="my-5 w-full">
          {posts.map((post, index) => (
            <section key={`post${index}`} className="inline-block w-full my-5">
              <h2 style={{fontSize: '1.75em'}}><Link to={`/${post.slug}`}>{post.title}</Link></h2>
              <span style={{fontStyle: 'italic', color: '#444'}}>{(new Date(post.date).toLocaleDateString('eu-ES', { year: 'numeric', month: 'short', day: 'numeric' }))}</span>
              <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
            </section>
          ))}
        </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allWpPost(
      limit: 20
      sort: { fields: date, order: DESC }
     ) {
      nodes {
        title
        slug
        excerpt
        date(formatString: "YYYY-MM-DD")
      }
     }
  }`